export const useDefaultSettingsPath = () => {
  const route = useRoute()
  const fullSlug = useState('fullSlug', () => {})
  const defaultFooterPath = ref('')
  const defaultHeaderPath = ref('')

  // eslint-disable-next-line complexity
  const setFullSlug = (newFullSlug) => {
    if (!fullSlug.value || newFullSlug) {
      fullSlug.value = newFullSlug
    }

    const regex = /inko|pflege|dev/g
    const routerPath = route.fullPath.split('/').slice(1, 2).join('').match(regex)
    const getUrlToSplit = fullSlug?.value?.full_slug.split('/')
    const curaboxPath = `${getUrlToSplit?.slice(0, getUrlToSplit?.indexOf(route.params.slug[0])).join('/')}`
    const matches = fullSlug?.value?.full_slug.match(
      new RegExp(`^${curaboxPath}/(inko|pflege|dev)`)
    )
    const settingsCuraboxPath = fullSlug?.value?.full_slug
      ? curaboxPath?.replace('www', 'settings')
      : 'product-domains/curabox/settings/de'

    defaultHeaderPath.value = matches
      ? `${settingsCuraboxPath}/${matches[1]}/header`
      : routerPath
        ? `${settingsCuraboxPath}/${routerPath[0]}/header`
        : `${settingsCuraboxPath}/header`

    defaultFooterPath.value = matches
      ? `${settingsCuraboxPath}/${matches[1]}/footer`
      : routerPath
        ? `${settingsCuraboxPath}/${routerPath[0]}/footer`
        : `${settingsCuraboxPath}/footer`
  }

  return {
    setFullSlug,
    defaultHeaderPath,
    defaultFooterPath
  }
}
